<template>
  <div style="width: 100vw;">
    <div class="banner">
      <div
        :class="saveCheck[index] ? hasCheck : font"
        @click="changePage(item.path,index)"
        v-for="(item,index) in pageList"
        :index="index"
      >
        <i :class="item.icon" style="font-size: 1.2rem;
    line-height: 2.73vh"></i>
        <div class="fontSon" style="margin-top: 5px;">{{ item.pageName }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "banner",
  data() {
    return {
      pageList: [
        {
          pageName: "首页",
          icon: "fontSon iconfont icon-shouye",
          path: "/home"
        },
        {
          pageName: "前往兑换",
          icon: "el-icon-present",
          path: "/activity"
        },{
          pageName: "有物商城",
          icon: "el-icon-suitcase-1",
          path: "/activity"
        },
        {
          pageName: "我的",
          icon: "el-icon-user",
          path: "/user"
        }
      ],
      saveCheck: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      hasCheck: "hasCheck",
      font: "font"
    };
  },
  methods: {
    changePage(path, index) {
      if(index == 2){
        window.location.href = 'https://shop.reportauto.cn/'
      }else{
        for (let i = 0; i < 4; i++) {
          this.saveCheck[i] = false;
        }
        this.saveCheck[index] = true;
        let pathOne = {
          path
        };
        this.$router.push(pathOne);
      }
      
    },
    reUseChangeSaveCheck(){
      for(const key in this.saveCheck){
        this.saveCheck[key]=false;
      }
    }
  },
  mounted() {
    this.$bus.$on("changeBannerToHome", () => {
      this.reUseChangeSaveCheck()
      this.saveCheck[0]=true;
    });
    this.$bus.$on("changeBannerToActive", () => {
      this.reUseChangeSaveCheck()
      this.saveCheck[1]=true;
    });
    this.$bus.$on("changeBannerToUser", () => {
      this.reUseChangeSaveCheck()
      this.saveCheck[3]=true;
    });
  }
};
</script>
<style lang="scss" scoped>
.banner {
  width: 80vw;
  margin:6vw 10vw;
  height: 8vh;
  border-radius: 15px;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: -1px 1px 10px #cacaca;
  display: flex;
}
.iconfont {
  font-size: 1.2rem;
  line-height: 2.73vh;
}
.font:nth-child(0) {
  flex: 2;
  display: flex;
  align-content: center;
  justify-content: center;
}
.font {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.55rem;
}
.hasCheck {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.55rem;
  color: #ffd701;
}
</style>